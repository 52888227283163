const configuration = {
    address: 'партер, секция Г, Ул. „Св. Киприян“ 262, 1799 ж.к. Младост 2, София, Bulgaria',
    phone: '+359887855558',
    phone_read: '+359 887855558',
    working_hours: {
        monday: '10:00 - 20:00',
        tuesday: '10:00 - 20:00',
        wednesday: '10:00 - 20:00',
        thursday: '10:00 - 20:00',
        friday: '10:00 - 20:00',
        saturday: '10:00 - 20:00',
        sunday: '10:00 - 20:00',
    },
    all_pages: [
        {
            'key': 'index',
            'name': 'Haчало',
            'path': '/',
        },
        {
            'key': 'about',
            'name': 'За нас',
            'path': '/about',
        },
        {
            'key': 'nails',
            'name': 'Маникюр и Педикюр',
            'path': '/service/nails',
        },
        {
            'key': 'hair',
            'name': 'Фризьорски услуги',
            'path': '/service/hair',
        },
        {
            'key': 'cosmetics',
            'name': 'Козметични услуги',
            'path': '/service/cosmetics',
        },
        {
            'key': 'pricing',
            'name': 'Ценоразпис',
            'path': '/pricing',
        },
        {
            'key': 'contact',
            'name': 'Контакти',
            'path': '/contact',
        },
    ],
    people: [
        {
            'name': 'Габриела Димитрова',
            'pro': 'маникюрист',
            'image': '/images/gabi.png',
            'quote': 'Занимавам се с маникюр и педикюр вече повече от десетилетие, като единстеното нещо, което не се е променило, е любовта ми към тази професия. От изключително значение за мен са най-високото качество, перфектните детайли и безпрекословната дисциплина, а пък моята цел - удовлетворени и щастливи клиенти'
        },
        {
            'name': 'Валентина Василева',
            'pro': 'козметик',
            'image': '/images/valq.png',
            'quote': 'Повече от 20години се занимавам професионално с козметика. С първа, втора и трета квалификационна степен в професията. За мен работата е преди всичко страст и удоволствие. Вярвам, че всеки заслужава да се чувства щастлив.'
        },
        {
            'name': 'Деси Андонова',
            'pro': 'фризьор',
            'image': '/images/desi.png',
            'quote': ''
        },
        {
            'name': 'Прасковья Кара - Поли',
            'pro': 'маникюрист',
            'image': '',
            'quote': ''
        },
    ]
}

export default configuration;
